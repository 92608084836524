<template>
  <div>
    <div class="card-title mb-3">
      {{ $t("role.settings.producer.title") }}
    </div>
    <div>
      <TB :tb_config="tb">
        <e-columns>
          <e-column
            field="id"
            headerText="ID"
            type="number"
            :isPrimaryKey="true"
            is-identity="true"
            :allowEditing="false"
            clipMode="EllipsisWithTooltip"
          ></e-column>
          <e-column
            field="producer.role.name"
            headerText="Produttore"
            clipMode="EllipsisWithTooltip"
            :allowEditing="false"
          ></e-column>
          <e-column
            field="market_wh"
            :headerText="$t('models.mpc.market_wh')"
            clipMode="EllipsisWithTooltip"
            foreignKeyValue="address.to"
            :dataSource="whData"
            foreignKeyField="id"
            :allowEditing="false"
          ></e-column>
          <e-column
            field="price_mode"
            :headerText="$t('models.mpc.price_mode')"
            clipMode="EllipsisWithTooltip"
            foreignKeyValue="label"
            :dataSource="PriceModeData"
            foreignKeyField="id"
            editType="dropdownedit"
          ></e-column>
          <e-column
            field="recharge"
            :headerText="$t('models.mpc.recharge')"
            type="number"
            clipMode="EllipsisWithTooltip"
            defaultValue=0
            :edit="rechargeParams"
          ></e-column>
          <e-column
            field="producer.role.country"
            headerText="Nazione"
            clipMode="EllipsisWithTooltip"
            :isPrimaryKey="true"
            is-identity="true"
            :allowEditing="false"
          ></e-column>
          <e-column
            field="producer.role.tel"
            headerText="Telefono"
            clipMode="EllipsisWithTooltip"
            :isPrimaryKey="true"
            is-identity="true"
            :allowEditing="false"
          ></e-column>
          <e-column
            field="producer.role.info_contact"
            headerText="Contatti"
            clipMode="EllipsisWithTooltip"
            :isPrimaryKey="true"
            is-identity="true"
            :allowEditing="false"
          ></e-column>
        </e-columns>
      </TB>
    </div>
  </div>
</template>
<script>
import { GetDataManager, GetDataManagerNew } from "../../../ds/index";
import TB from "../../../common/TB";
import { DropDownList } from "@syncfusion/ej2-dropdowns";
import { DataManager, Query } from "@syncfusion/ej2-data";
let stateElem, stateObj;
export default {
  components: {
    TB,
  },
  data() {
    return {
      tb: {
        data: GetDataManager("role_marketproducer_connections", [
          this.$store.state.role.id,
        ]),
        searchOptions: {
          fields: [
            // "user.first_name",
            // "user.last_name",
            "producer.role.name",
            "id",
          ],
        },
        editSettings: {
          allowEditing: true,
          allowAdding: false,
          allowDeleting: false,
          mode: "Normal",
        },
        toolbar: ["Edit", "Update", "Cancel"],
        // reload_on_save:true,
      },

      PriceModeData: new DataManager([
        { id: 0, label: this.$t("models.mpc.price_modes.optionNone") },
        { id: 1, label: this.$t("models.mpc.price_modes.optionTrue") },
        { id: 2, label: this.$t("models.mpc.price_modes.optionFalse") },
      ]),

      rechargeParams: {
        // create: () => {
        //   stateElem = document.createElement("input");
        //   return stateElem;
        // },
        // read: () => {
        //   if (stateObj){
        //     return stateObj.value;
        //   }
        //   return stateElem.getAttribute("producer_id");
        // },
        // destroy: () => {
        //   if (stateObj){
        //     stateObj.destroy();
        //   }
        // },
        // write: (args) => {
        //   if (args.rowData.producer.id > 0){
        //     stateElem.value=args.rowData.producer.role.name;
        //     stateElem.setAttribute("producer_id", args.rowData.producer.id);
        //   }else{
        //     stateObj = new DropDownList({
        //       dataSource: GetDataManagerNew("role_marketproducer", [
        //         this.$store.state.role.id,
        //       ]),
        //       fields: { value: "id", text: "role.name" },
        //       value: args.rowData.producer.id,
        //       enabled: !(args.rowData.producer.id > 0),
        //       query: new Query().take(10),
        //         // .where("country_id", "equal", args.rowData.address.country)
        //         // .take(10),
        //       placeholder: "Seleziona un produttore",
        //       floatLabelType: "Never",
        //       allowFiltering: true,
        //       actionComplete: () => false,
        //     });
        //     stateObj.appendTo(stateElem);
        //   }
          
        // },
      },
      whData: GetDataManager("role_market_planningwh", [
        this.$store.state.role.id,
      ]),
      tsFormat: { type: "dateTime", format: "dd/MM/yyyy HH:mm:ss" },
    };
  },
};
</script>
