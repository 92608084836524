<template>
  <div>
    <div>
    <CCard >
      <CTabs :activeTab.sync="activeTab">
        <CTab title="Impostazioni generali">
          <div class="d-flex justify-content-center" v-if="loading">
            <CSpinner color="info" />
          </div>

          <form action="" @submit.prevent="onSubmit" v-if="MarketData">
            <CRow class="align-items-center mb-2">
              <CCol md="4">
                <strong>Prezzo automatico</strong>
                <p>
                  Metti in vendita tutti i prodotti dei produttori connessi applicando un ricarico
                </p>
              </CCol>
              <CCol md="2">
                <CSwitch
                  variant="3d"
                  size="sm"
                  color="secondary"
                  :checked.sync="MarketData.auto_price"
                />
              </CCol>
            </CRow>
            

            <CRow>
              <CCol md="3">
                <CInput
                  v-if="MarketData.auto_price"
                  v-model="MarketData.d_margin"
                  :disabled="!MarketData.auto_price"
                  append="%"
                  type="number"
                  step="0.1"
                  min="0"
                  custom
                >
                  <template #label>
                    <label>
                      <strong>Ricarico</strong>
                      <em class="text-danger">*</em>
                    </label>
                  </template>
                </CInput>
              </CCol>
            </CRow>

            <vue-ladda
              :loading="saving"
              data-style="zoom-in"
              button-class="btn btn-primary mt-3"
              type="submit"
            >
              SALVA CONFIGURAZIONE
            </vue-ladda>
          </form>
        </CTab>
        <CTab title="Produttori">
          <SettingsProducer v-if="activeTab == 1" />
        </CTab>
      </CTabs>
    </CCard>
    </div>
  </div>
</template>

<script>
// import { CircleStencil, Cropper } from "vue-advanced-cropper";
import { DataManager, Query } from "@syncfusion/ej2-data";
import { GetDataManager, ApiCall } from "../../../ds/index";
import DrfAdaptor from "../../../http/EJ2_adapter";
import { ROLE_TYPE } from "../../../helpers/acl"; 
import SettingsProducer from "./SettingsProducer";
import { apiUrl } from "../../../http";
// import ProfileLogo from "./ProfileLogo";
// import ProfileImages from "./ProfileImages";
// /api/market/42/settings/1/
export default {
  name: "ProfileData",
  data() {
    return {
      activeTab: 0,

      loading: true,
      saving: false,
      MarketData: null,
      // wallet: null,
      // role: null,
    };
  },
  mounted() {
    this.getMarket();
    // this.getWallet();
    // this.getProducer();

    // let self = this;
    // // Update propduct Info
    // ApiCall(
    //   GetDataManager("auth_frole", [], this.$store.state.role.id),
    //   new Query(),
    //   function (e) {
    //     // self.pp = e.result;
    //     self.role = e.result;
    //   }
    // );
  },
  computed: {
    // planner_view() {
    //   if ([ROLE_TYPE.MARKET, ROLE_TYPE.SHOP].indexOf(this.$store.state.role.r_type) > -1){
    //     return true
    //   }
    //   return false;
    // },
  },
  methods: {
    getMarket() {
      const self = this;
      this.loading = true;

      const roleId = this.$store.state.role.id;
      const marketId = this.$store.state.role.market__id;
      const dm = GetDataManager("role_market", [roleId, ], marketId);

      ApiCall(
        dm,
        new Query(),
        (response) => {
          self.loading = false;
          self.MarketData = response.result;
          // const invoicing = get(self.familyRoleData, "extra.invoicing", null);
          // this.invoicing = parseInvoicingSettings(invoicing);
        },
        (response) => {
          self.loading = false;
        }
      );
    },
    onSubmit() {
      const roleId = this.$store.state.role.id;
      const requestParams = {
        id: this.MarketData.id,
        auto_price: this.MarketData.auto_price,
        d_margin: this.MarketData.d_margin,
      };

      this.saving = true;
      GetDataManager("role_market", [roleId, ])
        .update("", requestParams)
        .then(() => {
          this.saving = false;
          // this.getRoleById(roleId).then((data) => {
          //   this.$store.commit("setRole", data);
          // });
          this.$store.dispatch("toaster/add", {
            title: "OK!",
            text: "Configurazione aggiornata con successo",
            color: "success",
            autohide: true,
          });
        })
        .catch((error) => {
          console.error(error);
          this.saving = false;
          this.$store.dispatch("toaster/add", {
            title: "Attenzione!",
            text: "Si prega di controllare i dati inseriti e riprovare",
            color: "info",
            autohide: true,
          });
        });
    },
  },
  components: {
    SettingsProducer,
  },
};
</script>
